/* eslint-disable indent */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import kebabCase from 'lodash/kebabCase'
import { RichText } from 'prismic-reactjs'

import { theme } from '../../styles'
import Text from '../common/Text'
import Space from '../common/Space'
import getImage from '../../utils/getImage'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
  padding-top: ${p => (p.hasTopPadding ? '80px' : '0px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '80px' : '0px')};

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    padding-top: ${p => (p.hasTopPadding ? '48px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '48px' : '0px')};
  }

  @media (max-width: 800px) {
    padding-top: ${p => (p.hasTopPadding ? '40px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '40px' : '0px')};
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  height: 100%;
  margin-left: -300%;
  top: 0;
  z-index: -1;
  background-color: ${p => p.theme.color[p.bgColor]};

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const Header = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`

const TabWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  z-index: 2;
  &:focus {
    outline: none;
  }

  @media (max-width: 1050px) {
    margin-top: 24px;
  }

  @media (max-width: 800px) {
    margin-top: 0px;
  }
`

const TabButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${p => theme.color[p.backgroundColor]};
  border-radius: 8px;
  padding: 16px;

  @media (max-width: 1050px) {
    padding: 0 32px;
  }

  @media (max-width: 800px) {
    padding: 0 12px;
  }
`

const TabButton = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  margin: 0px 5px;
  background-color: ${p => p.theme.color[p.tabColor]};
  border-radius: 8px;
  position: relative;

  &:first-of-type {
    margin-left: 0px;
  }
  &:last-child {
    margin-right: 0px;
  }

  p {
    color: ${theme.color.offBlack};
    margin-bottom: 0px !important;
  }

  ${p =>
    p.selected &&
    css`
      background-color: ${theme.color.purple500};
      border: 3px solid ${theme.color.purple500};
      box-shadow: none;
      p {
        color: ${theme.color.white};
      }
    `}

  @media (max-width: 1050px) {
    padding: 10px;
  }

  @media (max-width: 800px) {
    margin: 0px 2px;
    padding: 4px;
  }
`

const TabButtonText = styled(Text)`
  @media (max-width: 1050px) {
    ${p =>
      p.selected &&
      css`
        display: none;
      `};
  }
  @media (max-width: 500px) {
    p {
      font-size: ${p => p.theme.fonts.size.tinyBody}!important;
    }
  }
`

const Icon = styled.img`
  display: none;

  @media (max-width: 1050px) {
    ${p =>
      p.selected &&
      css`
        display: flex;
        max-width: 60px;
        min-width: 45px;
        max-height: 60px;
        object-fit: contain;
        width: 100%;
      `}
  }
`

const TabPanelWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${p => p.theme.color[p.panelColor]};
  align-items: flex-start;
  padding: 64px 16px 0px 16px;
  border: 3px solid transparent;

  &:focus {
    outline: ${theme.focusOutline};
  }

  ${p =>
    !p.selectedTab &&
    css`
      height: 0px !important;
      min-height: 0px;
      visibility: hidden;
    `}

  @media (max-width: 1350px) {
    min-height: 425px;
  }

  @media (max-width: 1050px) {
    padding: 0px;
  }

  @media (max-width: 800px) {
    min-height: 350px;
  }
`

const PanelTextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ul {
    text-align: left;
    padding-left: 20px;
  }
  p {
    margin-bottom: 0px;
  }

  @media (max-width: 1050px) {
    align-items: center;
    padding: 24px;
  }

  @media (max-width: 500px) {
    padding: 24px 12px;
  }
`

const PanelTitleText = styled(Text)`
  margin-bottom: 16px;
  p {
    margin-bottom: 0px !important;
  }
`

const PanelImageWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: 1050px) {
    display: none;
  }
`

const PanelImage = styled.img``

const modifyColors = bgColor => {
  switch (bgColor) {
    case 'offWhite':
      return {
        backgroundColor: 'offWhite',
        tabColor: 'white',
      }
    case 'white':
      return {
        backgroundColor: 'white',
        tabColor: 'offWhite',
      }
    default:
      return {
        backgroundColor: 'white',
        tabColor: 'offWhite',
      }
  }
}

const TabButtonComponent = ({
  tabTitle,
  selectedTab,
  setSelectedTab,
  selectedTabMobileIcon,
  tabItems,
  tabColor,
  index,
}) => {
  const tabTitleText = kebabCase(tabTitle.props.render[0].text)
  const isSelected = selectedTab === tabTitleText

  return (
    <TabButton
      selected={isSelected}
      onClick={() => setSelectedTab(tabTitleText)}
      role="tab"
      aria-selected={isSelected}
      id={tabTitleText}
      aria-controls={`${tabTitleText}-panel`}
      tabIndex={isSelected ? null : -1}
      tabColor={tabColor}
      onKeyDown={e => {
        if (e.key === 'ArrowRight' && index < tabItems.length - 1) {
          e.target.nextElementSibling.focus()
        }
        if (e.key === 'ArrowLeft' && index > 0) {
          e.target.previousElementSibling.focus()
        }
      }}
    >
      <TabButtonText center font="bodyHeavy" selected={isSelected} richText>
        {tabTitle}
      </TabButtonText>
      {getImage(selectedTabMobileIcon, Icon, { selected: isSelected })}
    </TabButton>
  )
}

const TabPanelComponent = ({ tabTitle, tabPanelTitle, tabPanelText, tabPanelImage, selectedTab, backgroundColor }) => {
  const tabTitleText = kebabCase(tabTitle.props.render[0].text)
  const isSelected = selectedTab === tabTitleText

  return (
    <TabPanelWrapper
      selectedTab={isSelected}
      id={`${tabTitleText}-panel`}
      role="tabpanel"
      aria-labelledby={tabTitleText}
      tabIndex={isSelected ? 0 : -1}
      hidden={!isSelected}
      panelColor={backgroundColor}
      className="focus-outline"
    >
      <PanelImageWrapper>{getImage(tabPanelImage, PanelImage, { noAlt: true })}</PanelImageWrapper>
      <Space width={16} tabletWidth={0} />
      <PanelTextWrapper>
        <PanelTitleText font="heading3" role="heading" aria-level="3" richText>
          {tabPanelTitle}
        </PanelTitleText>
        <Text font="body" richText>
          {tabPanelText}
        </Text>
      </PanelTextWrapper>
    </TabPanelWrapper>
  )
}

const FeaturedTabs = ({ items, primary }) => {
  const title = <RichText render={primary.title1.richText} />
  const subtitle = <RichText render={primary.subtitle.richText} />
  const { color } = primary
  const anchorId = primary.anchor_id
  const hasTopPadding = primary?.has_top_padding
  const hasBottomPadding = primary?.has_bottom_padding

  const tabs = items.map(item => kebabCase(item.tab_title.text))
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const modifiedColor = modifyColors(color)

  return (
    <Wrapper
      id={anchorId || null}
      hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
      hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
    >
      <Header>
        <Text font="heading2" center richText>
          {title}
        </Text>
        <Space height={16} />
        {subtitle && (
          <>
            <Text font="body" center richText>
              {subtitle}{' '}
            </Text>
            <Space height={0} tabletHeight={24} />
          </>
        )}
      </Header>
      <TabWrapper>
        <TabButtonWrapper role="tablist" backgroundColor={modifiedColor.tabColor}>
          {items &&
            items.map((item, i) => (
              <TabButtonComponent
                index={i}
                tabItems={items}
                tabTitle={<RichText render={item.tab_title.richText} />}
                id={kebabCase(item.tab_title.text)}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                selectedTabMobileIcon={item.active_mobile_tab_icon}
                tabColor={modifiedColor.tabColor}
                key={item.tab_title.text}
              />
            ))}
        </TabButtonWrapper>
        {items &&
          items.map((item, i) => (
            <React.Fragment key={item.tab_title.text}>
              <TabPanelComponent
                tabTitle={<RichText render={item.tab_title.richText} />}
                tabPanelTitle={<RichText render={item.tab_panel_title.richText} />}
                tabPanelText={<RichText render={item.tab_panel_text.richText} />}
                tabPanelImage={item.tab_panel_image}
                tabMobileIcon={item.mobile_tab_icon}
                selectedTab={selectedTab}
                backgroundColor={modifiedColor.backgroundColor}
              />
            </React.Fragment>
          ))}
      </TabWrapper>
      <BG
        className="bg"
        bgColor={modifiedColor.backgroundColor}
        hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
      />
    </Wrapper>
  )
}

export default FeaturedTabs

export const query = graphql`
  fragment FeaturedTabs on PrismicPageDataBodyFeaturedTabs {
    ...SliceBase
    items {
      active_mobile_tab_icon {
        alt
        url
        gatsbyImageData(width: 60)
      }
      tab_panel_image {
        alt
        url
        gatsbyImageData(width: 540)
      }
      tab_panel_text {
        ...TextContent
      }
      tab_panel_title {
        ...TextContent
      }
      tab_title {
        ...TextContent
      }
    }
    primary {
      anchor_id
      has_top_padding
      has_bottom_padding
      color
      subtitle {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
    }
  }
`
